import React from 'react';
import {Link} from 'react-router-dom';
import CtLogo from '../../images/ctlogo.png';

function CtRescue() {
    return (
        <section className='section pb-0'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h1 className='main-heading text-center'>Chunkz & Tubz Rescue Foundation</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 text-center'>
                             <img src={CtLogo} class="img-fluid" alt='Rescue Logo'/>
                        </div>
                        <div className='col-md-6 pt-5'>
                            <h1 className='sub-heading text-left'>
                                SAVING THE WORLD 
                                <br/>
                                ONE BULLDOG AT A TIME
                            </h1>
                            <h4>We are a 501(c)3 nonprofit that focuses on rescuing, rehabilitating, & rehoming of English Bulldogs</h4>
                        </div>
                    </div>
                    {/* <div className='row'>
                        <div className='col-md-12 text-center mt-5'>
                            <form action="https://www.paypal.com/donate" method="post" target="_top">
                                <input type="hidden" name="hosted_button_id" value="SJB46JZ2NQA98" />
                                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        </div>
                    </div> */}
                </div>
            </section>
    );
}

export default CtRescue;