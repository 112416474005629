import React from 'react';

function VMC() {
    return (
        <section className='section bg-c-light border-top'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 mb-2 text-center'>
                        <h3 className='main-heading text-white'>Our Vision</h3>
                        <div className='underline mx-auto'></div>
                    </div>
                    <div className='col-md-12 text-center text-white'>
                        <p>
                        Everyday, thousands of dogs in the U.S. are euthanized for lack of space due to the overpopulation crisis. 
                        This crisis is a result of unregulated backyard breeding. Our goal is to rescue, rehabilitate, 
                        educate, & advocate for the bulldog breed & work towards policy changes for backyard breeding. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default VMC;