import React from 'react';
import './App.css'
import Navbar from './components/inc/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Donate from './components/pages/Donate';
import Footer from './components/inc/Footer';
import Foster from './components/pages/Foster';
import Contact from './components/pages/Contact';
import {Routes, Route} from 'react-router-dom';

function App() {
  return (
    <div>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/donate' element={<Donate/>} />
        <Route path='/foster' element={<Foster/>} />
        <Route path='/contact' element={<Contact/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
