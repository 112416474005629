import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Adopt from "../../images/Adopt.png";
import Donate from "../../images/Donate.png";
import Foster from "../../images/Foster.png";
import Volunteer from "../../images/Volunteer.png";

function Services() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_nlaiem6",
        "template_s1x0u0b",
        form.current,
        "vAHPFszjPzCZm_RRw"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mx-auto pb-5">
      <div class="col">
        <div class="card h-100 border-0">
          <img src={Donate} class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title text-center fw-bold">Donate</h5>
            <p class="card-text text-center">
              Your donations enable us provide medical care, food, & supplies.
            </p>
          </div>
          <div class="card-footer bg-white border-0 text-center">
            <a href="/donate" class="btn btn-primary">
              Donate
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100 border-0">
          <img src={Adopt} class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title text-center fw-bold">Adopt</h5>
            <p class="card-text text-center">
              Bring home a wrinkly couch potato!
            </p>
          </div>
          <div class="card-footer bg-white border-0 text-center">
          <a href="/Contact" class="btn btn-primary">
              Adopt
            </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100 border-0">
          <img src={Foster} class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title text-center fw-bold">Foster</h5>
            <p class="card-text text-center">Become a life saving foster.</p>
          </div>
          <div class="card-footer bg-white border-0 text-center">
          <a href="/Contact" class="btn btn-primary">
              Foster
          </a>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100 border-0">
          <img src={Volunteer} class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title text-center fw-bold">Volunteer</h5>
            <p class="card-text text-center">
              Help out with adoption events, fundraisers, & more!
            </p>
          </div>
          <div class="card-footer bg-white border-0 text-center">
          <a href="/Contact" class="btn btn-primary">
              Volunteer
          </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
