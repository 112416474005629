import React, { useRef } from "react";

import emailjs from "@emailjs/browser";
import WhoWeAre from "../images/WhoWeAre.png";

function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
          "service_nlaiem6",
          "template_s1x0u0b",
          form.current,
          "vAHPFszjPzCZm_RRw"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    };

  return (
    <div>
      <div className="bg-c-light w-100 pb-4">
        <img
          src={WhoWeAre}
          className="mx-auto d-block w-25 h-25"
          alt="About Us"
        />
        <h1 className="fw-bold text-center text-white">Contact Us!</h1>
      </div>
      <div class="container py-4">
        <form id="contactForm" ref={form} onSubmit={sendEmail}>
          <div class="mb-3">
            <label class="form-label" for="name">
              Name
            </label>
            <input
              class="form-control"
              id="name"
              type="text"
              placeholder="Name"
              name="user_name"
            />
          </div>
          <div class="mb-3">
            <label class="form-label" for="emailAddress">
              Email Address
            </label>
            <input
              class="form-control"
              id="emailAddress"
              type="email"
              placeholder="Email Address"
              name="user_email"
            />
          </div>
          <div class="mb-3">
            <label class="form-label" for="message">
              Message
            </label>
            <textarea
              class="form-control form-control-height"
              id="message"
              type="text"
              placeholder="Message"
              name="message"
            ></textarea>
          </div>

          <div class="d-grid">
            <button class="btn btn-primary btn-lg" type="submit">
              Send Email
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
