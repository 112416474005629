import React from 'react';
import Slider1 from '../images/SliderTest.png'
import SliderGroup from '../images/IMG_0631.jpeg'
import SliderAdopt from '../images/SliderAdopt.jpg'
import SliderFoster from '../images/SliderFoster.jpg'

function Slider() {
    return(
        <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={SliderAdopt} class="d-block w-100" alt="..."/>
      <div class="carousel-caption d-md-block">
        <h2 className='fw-bold'><a href="https://www.adoptapet.com/shelter/192805-chunkz-tubz-rescue-foundation-lakewood-california" class="btn btn-donate text-default">View our adoptabulls!</a></h2>
      </div>
    </div>
    <div class="carousel-item">
      <img src={SliderFoster} class="d-block w-100" alt="..."/>
      <div class="carousel-caption d-md-block">
        <h2 className='fw-bold'>Help us save a life, become a foster!</h2>
      </div>
    </div>
    <div class="carousel-item">
      <img src={SliderGroup} class="d-block w-100" alt="..."/>
      <div class="carousel-caption d-md-block">
        <h2 className='fw-bold'><a href="https://www.meetup.com/long-beach-bulldog-meetup/" class="btn btn-donate text-default">Upcoming Events</a></h2>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    );
}

export default Slider;