import React from 'react';
import Donate1 from '../images/Donate1.jpg'
import Donate2 from '../images/Donate2.jpg'
import Donate3 from '../images/Donate3.jpg'
import Donate4 from '../images/Donate4.jpg'

function Donate() {
    return(
        <div className='section bg-c-light pb-0'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1 className='text-center text-white'>Donate to the wrinkly potatoes in our care!</h1>
                        <br/>
                        <h4 className='text-center text-white'>If you are a dog owner, you understand the cost of veterinary care. If you are a bulldog owner, 
                            you know Bulldogs are a sensitive breed with a plethora of extra care needs! Your generous donation 
                            is 100% tax deductible & it helps us fund medical costs, food, & training for our rescue pups. 
                        </h4>
                        <div className='text-center'>
                            <a href="https://www.paypal.com/donate/?hosted_button_id=PV4UUGZ2BSKBY" class="btn btn-donate text-default m-5">Donate with PayPal</a>
                            <a href="https://account.venmo.com/u/chunkzntubz" class="btn btn-donate text-default m-5">Donate with Venmo</a>
                            <a href="https://cuddly.com/shelter/258725/Chunkz-Tubz-Rescue" class="btn btn-donate text-default m-5">Donate through Cuddly</a>
                            <a href="https://causes.benevity.org/causes/840-883122450" class="btn btn-donate text-default m-5">Donate through Benevity</a>
                        </div>
                        <h4 className='text-center text-white'>Your donation is 100% tax deductible! </h4>
                        <h4 className='text-center text-white'>EIN: 88-3122450</h4>
                    </div>
                </div>
            </div>
            <div class="row g-0">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <img src={Donate1} class='donate-image' alt='services' />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <img src={Donate2} class='donate-image' alt='services' />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <img src={Donate3} class='donate-image' alt='services' />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <img src={Donate4} class='donate-image' alt='services' />
                </div>
            </div>
        </div>
    );
}

export default Donate;