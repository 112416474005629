import React from 'react';
import {Link} from 'react-router-dom';

function Footer() {
    return (
        <section className='section footer bg-dark text-white'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4'>
                    </div>
                    <div className='col-md-4'>
                        <div><Link to="/">Home</Link></div>
                        <div><Link to="/about">Our Story</Link></div>
                        {/* <div><Link to="/">Volunteer/Foster</Link></div> */}
                    </div>
                    <div className='col-md-4'>
                        <div>
                            <p className='text-white mb-1'>admin@chunkzntubzrescue.org</p>
                            <i class="bi bi-threads"></i>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer;