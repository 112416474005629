import React from 'react';
import AboutUs from '../images/aboutus.jpg'
import AboutUs1 from '../images/AboutUs1.jpg'
import AboutUs2 from '../images/AboutUs2.jpg'
import AboutUs3 from '../images/AboutUs3.jpg'
import AboutUs4 from '../images/AboutUs4.jpg'
import WhoWeAre from '../images/WhoWeAre.png'

function About() {
    return(
        <div>
            <div className='bg-c-light w-100 pb-4'>
                <img src={WhoWeAre} className='mx-auto d-block w-25 h-25' alt='About Us' />
                <h1 className='fw-bold text-center text-white'>Who We Are</h1>
                <h5 className='text-white text-center'>We are a 501(c)3 nonprofit that focuses on rescuing, rehabilitating, & rehoming of English Bulldogs</h5>
            </div>
            <div className='container'>
            <div className='row'>
                <div className='col-lg-6 mt-5'>
                    <h1>OUR STORY</h1>
                    <p className='text-left'>
                        Chunkz & Tubz Rescue Foundation is named after my belated English bulldog pups, Chunkz & Tubz. They were 10 & 11 months old when they passed in August 2021, 
                        as a result of negligent breeding. Benny and I had always wanted a bulldog, so we came home with Chunkz one day, whom we bought from a breeder on Craigslist. 
                        We knew nothing about the breed, nor the rescue world for that matter. Well, we loved Chunky so much that we got Tubby a month later, from the same breeder. We quickly 
                        learned about the breed and began taking them to a Bulldog specialist vet. We tried to be the best doggie parents we could be! People used to joke about how they 
                        could only imagine how it would be when we had human kids, since the boys were so spoiled. 
                        <br/>
                        <br/>
                        Unfortunately, Tubz wasn't set up for success in life--he had every 
                        brachycephalic issue imaginable (stenotic nares, hypoplastic trachea, you name it). He caught pneumonia at just 3 months old. We fought with him for as long as we could, 
                        but his body became antibiotic resistant. When we finally decided it was time to let him go, we came home to Chunkz who had passed away in his sleep. Unbeknownst 
                        to us, he had developed a cyst deep in his larynx that grew large enough to block his airway. He had just seen our vet a few days prior and was deemed a candidate for soft palate removal surgery. He was still warm by the time we came home to him. 
                        <br/>
                        <br/>
                        They say life can only be understood looking backwards, 
                        but it must be lived forwards. I don't think I will ever understand why we had to lose both on the same day & time. It's definitely a feeling I would never 
                        wish upon anyone. All I can express is gratitude for being their mama, in addition to all the love they brought into our lives.
                        <br/>
                        <br/>
                        So, on behalf of Chunky & Tubby--the "rowdy ruff boys" (who truthfully, weren't rowdy at all), this rescue is dedicated to saving English Bulldogs, 
                        as well as advocating for & educating about their wonderful breed! 💓
                    </p>
                </div>
                <div className='col-lg-6 mt-5 mb-5'>
                    <img src={AboutUs} className='about-us-img' alt='About Us' />
                </div>
            </div>
            <div class="row g-1">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <img src={AboutUs1} class='donate-image' alt='services' />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <img src={AboutUs2} class='donate-image' alt='services' />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <img src={AboutUs3} class='donate-image' alt='services' />
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <img src={AboutUs4} class='donate-image' alt='services' />
                </div>
            </div>
            </div>
        </div>
    );
}

export default About;