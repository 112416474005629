import React from 'react';
import Slider from '../inc/Slider'
import VMC from './inc/vmc';
import Services from './inc/services';
import Adopt from './inc/adopt';
import CtRescue from './inc/ctrescue';


function Home() {
    return(
        <div>
            <Slider/>
            <CtRescue/>
            <Services/>
            <VMC/>
            {/*<Adopt/> */}
        </div>
    );
}

export default Home;